import { mapGetters, mapMutations } from 'vuex'

import mixins from '@/mixins'

export default {
  name: 'events-view',
  mixins: [mixins],
  computed: {
    ...mapGetters({
      isGeneralLoading: 'eventsGeneral/isLoading',
      isContentLoading: 'eventsContent/isLoading',
      isSeoLoading: 'eventsSeo/seoLoading'
    }),
    isCreateRoute () {
      return this.$route.name.includes('.create')
    }
  },
  methods: {
    ...mapMutations({
      resetGeneral: 'eventsGeneral/SET_DATA',
      resetSeo: 'eventsSeo/SEO_SET_DATA',
      resetContent: 'eventsContent/CONTENT_SET_DATA'
    })
  },
  destroyed () {
    this.resetGeneral(null)
    this.resetSeo(null)
    this.resetContent(null)
  }
}
